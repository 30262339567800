import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Route, withRouter } from "react-router-dom";
import { batch } from "react-redux";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { authProtectedRoutes, betSlipRoutes, publicRoutes } from "./routes";
import { useIdleTimer } from "react-idle-timer";
import Cookies from "js-cookie";
// Import all middleware
import Authmiddleware from "./routes/route";
import { getAllLeagues } from "./store/Leagues/actions";
import { getAllLocations } from "./store/Locations/actions";
import AppLayout from "./components/Layouts/AppLayout";
// Import scss
import "./App.css";
import { logoutUser } from "./store/Auth/actions";
import BetSlip from "./pages/BetSlip/BetSlip";
import { getAllSports } from "./store/Sports/actions";
import { getAllBanners } from "./store/Banners/actions";
import { getUrlLastPart } from "./components/Common/Utils/helpers";
import { setActiveFooterTab, setActiveTab } from "./store/actions";
import CommingSoon from "./components/Layouts/CommingSoon";
import { getAllProducts } from "./store/Products/actions";
import SignupPopup from "./pages/Register/SignupPopup";
const isLoggedIn = sessionStorage.getItem("token") ? true : false;
function App(props) {
  const dispatch = useDispatch();
  const [showSignupModal, setSignupShowModal] = useState(false);
  // const [showInfoModal, setInfoShowModal] = useState(false);
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const isLoggedIn = sessionStorage.getItem("token") ? true : false;

  useEffect(() => {
    if (!dontShowAgain) {
      // Cookies.remove('dontShowModalAgain');
      const lastModalTime = Cookies.get("lastModalTime");
      const dontShowModalAgain = Cookies.get("dontShowModalAgain");
      const currentTime = new Date().getTime();

      if (isLoggedIn) {
        const deposit_through_popup = localStorage.getItem(
          "depositThroughPopup"
        );
        const reg_through_popup = JSON.parse(localStorage.getItem("user"))?.user
          ?.reg_through_popup;
        if (
          reg_through_popup == 1 &&
          deposit_through_popup == 0 &&
          (!lastModalTime || currentTime - lastModalTime >= 1 * 2 * 60 * 1000)
        ) {
          // setInfoShowModal(true);
        }
      }

      // 24 * 60 * 60 * 1000
      else if (
        !dontShowModalAgain &&
        (!lastModalTime || currentTime - lastModalTime >= 24 * 60 * 60 * 1000)
      ) {
        setSignupShowModal(true);
      }
    }
  }, [dontShowAgain, isLoggedIn]);
  const handleDontShowAgainChange = (event) => {
    setDontShowAgain(event.target.checked);
  };

  useEffect(() => {
    injectGA();
    if (getUrlLastPart() == "home") {
      dispatch(setActiveTab("home-upcoming"));
      dispatch(setActiveFooterTab("home"));
    } else {
      dispatch(setActiveTab(getUrlLastPart()));
    }
    getAllLookupsData();
  }, []);
  const injectGA = () => {
    if (typeof window == "undefined") {
      return;
    }
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    if (isLoggedIn) {
      let userId = JSON.parse(localStorage.getItem("user"))?.user?.id;
      gtag("set", { user_id: userId });
    }
    gtag("js", new Date());

    gtag("config", "G-K2LNFMWQBS");
  };
  const timeout = 1000 * 60 * 20; //ms * ss * mm

  const handleOnIdle = () => {
    const { history } = props;
    if (![undefined, null, ""].includes(sessionStorage.getItem("token"))) {
      dispatch(logoutUser(history));
    }
  };

  useIdleTimer({
    timeout,
    onIdle: handleOnIdle,
  });

  const getAllLookupsData = () => {
    batch(() => {
      dispatch(getAllLeagues());
      dispatch(getAllLocations());
      dispatch(getAllSports());
      dispatch(getAllBanners());
      dispatch(getAllProducts());
    });
  };

  return (
    <>
      {(showSignupModal ) && (
        <SignupPopup
          dontShowAgain={dontShowAgain}
          handleDontShowAgainChange={handleDontShowAgainChange}
          setSignupShowModal={setSignupShowModal}
          showSignupModal={showSignupModal}
          // showInfoModal={showInfoModal}
          // setInfoShowModal={setInfoShowModal}
        />
      )}
      <Router>
        <Switch>
          <Route path="/betSlip">
            <BetSlip />
          </Route>
          <Route path="/comming-soon">
            <CommingSoon />
          </Route>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={AppLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={AppLayout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
        </Switch>
      </Router>
    </>
  );
}

export default withRouter(App);
