import React, { useState, useEffect } from "react";
import Iframe from "react-iframe";
import ReactModal from "react-modal";

// ------------------------------------------ virt --------------------------------
import { useDispatch, useSelector } from "react-redux";
import Banners from "../../components/Common/Banners";
import {
  getAllCasinoGames,
  getGameUrl,
  searchGames,
} from "../../helpers/repository";
import { showMessage } from "../../components/Common/Toaster";
import MessageBox from "../../components/Common/MessageBox";
import ButtonLoader from "../../components/Common/ButtonLoader";

const Casinos = () => {
  const [arr, setArr] = useState([]);
  const [games, setGames] = useState([]);
  const [iframeURL, setIframeURL] = useState(null);
  const [gameTypes, setGameTypes] = useState([]);
  const [gameSearchTypes, setGameSearchTypes] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isNewGamesCollapsed, setIsNewGamesCollapsed] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);

  const [tableGames, setTableGames] = useState([
    "Blackjack",
    "Baccarat",
    "Baccarat New",
    "Roulette",
  ]);
  const [types, setTypes] = useState([
    {
      old_name: "Video Slots",
      new_name: "All Slots",
    },
    {
      old_name: "Table Games",
      new_name: "Table Games",
    },
    // {
    //   old_name: "Live games",
    //   new_name: "Live Casino",
    // },
    {
      old_name: "Classic Slots",
      new_name: "Classic Slots",
    },
    {
      old_name: "Video Poker",
      new_name: "Video Poker",
    },
  ]);
  const [selectedGameType, setSelectedGameType] = useState(null);
  const [loadingMessage, setLoadingMessage] = useState("Loading Data...");

  useEffect(() => {
    getAllCasinoGames(successGetCasinoGames, errorGetCasinoGames);
  }, []);

  const successGetCasinoGames = (data) => {
    setGameTypes(data);
  };

  const errorGetCasinoGames = () => {
    console.log("something went wrong.");
  };

  const popularGameAccordion = () => {
    setIsCollapsed(!isCollapsed);
  };

  const newGameAccordion = () => {
    setIsNewGamesCollapsed(!isNewGamesCollapsed);
  };

  useEffect(() => {
    if (gameTypes.length > 0) {
      setSelectedGameType(gameTypes[0].id);
      setLoadingMessage(null);
    }
  }, [gameTypes]);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "50%",
    },
  };

  useEffect(() => {
    var myArr = [];
    var mynumber = 159;
    for (var i = 1; i <= mynumber; i++) {
      myArr.push(i);
    }

    setArr(myArr);
  }, []);

  const handleCasinosChange = () => {
    setSelectedGameType(document.getElementById("ddn_type").value);
  };

  const Banner = useSelector((state) => state?.Banner?.Index).filter(
    (banner) => banner["page_name"] == "Casino Page"
  );

  const _openGameUrl = (id) => {
    getGameUrl(id, successGetGameUrl, errorGetGameUrl);
  };

  const successGetGameUrl = (res) => {
    setIframeURL(res.gameURL);
  };

  const errorGetGameUrl = (error) => {
    showMessage("error", error);
  };

  const handleSubmit = (e, textValue = false) => {
    e.preventDefault();
    var id = selectedGameType;
    var search = searchValue;
    if (textValue) {
      search = "";
      document.getElementById("txtSearch").value = "";
    }
    if (search == null || search == "") {
      setSearchResult(false);
      return;
    }
    setSearchResult(true);
    setButtonLoader(true);
    searchGames(id, search)
      .then((response) => {
        setGameSearchTypes(response?.data?.data);
        gameSearchTypes.length;
      })
      .catch((err) => {
        console.log("no data found");
      })
      .finally(() => {
        setButtonLoader(false);
      });
  };

  const handleClearClick = () => {
    setSearchValue("");
    setSearchResult(false);
  };

  return (
    <>
      {/*<div style={{textAlign:"center",marginTop:"40px",marginBottom:"40px"}}>*/}
      {/*  <h1>Coming Soon</h1>*/}
      {/*</div>*/}
      <Banners Banner={Banner} />
      <div className="BLM-commonPage">
        <div className="BLM-commonPage-content BLM-common">
          <div className="container">
            <div className="row mb-2">
              <div className="BLM-select" style={{ backgroundColor :  "#333333" }}>
                <select id="ddn_type" onChange={handleCasinosChange}>
                  {types.map((gt) => {
                    return (
                      <option
                        value={
                          gameTypes?.find((g) => g.description == gt.old_name)
                            ?.id ?? "Table Games"
                        }
                      >
                        {gt.new_name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            {selectedGameType == 1 ? (
              <div class="BLM-search">
                <form onSubmit={handleSubmit}>
                  <div className="BLM-serachBox">
                    <div className="BLM-form-control">
                      <input
                        type="text"
                        id="txtSearch"
                        placeholder="Search for Game Name"
                        onChange={(e) => {
                          const value = e.target.value;
                          // Allow only alphabetic characters, digits, and spaces
                          const regex = /^[a-zA-Z0-9 ]*$/;
                          if (regex.test(value)) {
                            setSearchValue(value);
                          }
                        }}
                        value={searchValue}
                      />
                    </div>
                    {searchValue && (
                      <div
                        className="BLM-searchbtn"
                        id="disableSearchButtonClick"
                        onClick={handleClearClick}
                        style={{
                          backgroundColor: "#eee",
                          color: "black",
                          fontSize: "20px",
                        }}
                      >
                        x
                      </div>
                    )}
                    <button
                      className="BLM-searchbtn"
                      id="disableSearchButtonClick"
                      type="submit"
                      style={{ background:"#fef102" }}
                    >
                      {buttonLoader ? (
                        <ButtonLoader />
                      ) : (
                        <i className="sb-icon_search" />
                      )}
                    </button>
                  </div>
                </form>
              </div>
            ) : (
              ""
            )}
            {searchResult ? (
              <div className="accordion mt-3">
                  <div
                    style={{
                      backgroundColor: "#d7d7d7",
                      color:"black",
                      padding: "20px",
                      cursor: "pointer",
                    }}
                  >
                    <div className="row img_row">
                      {gameSearchTypes.length > 0 ? (
                        <h5 className="mb-3">Search Results</h5>
                      ) : (
                        ""
                      )}
                      {gameSearchTypes.length > 0 ? (
                        gameSearchTypes.map((game) => (
                          <div
                            key={game.game_id}
                            className="col-lg-3 col-6 mt-2"
                            onClick={() => _openGameUrl(game.game_id)}
                          >
                            <img
                              style={{ borderRadius: "10px" }}
                              src={`https://gamlabs.prerelease-env.biz/game_pic/rec/160/${game.game_id}.png`}
                              alt={game.game_name}
                            />
                          </div>
                        ))
                      ) : (
                        <h6>No results</h6>
                      )}
                    </div>
                  </div>
              </div>
            ) : (
              <div>
                {![null, ""].includes(loadingMessage) ? (
                  <MessageBox data={loadingMessage} />
                ) : (
                  <>
                    {selectedGameType == 1 ? (
                      <div className="accordion mt-3">
                          <div
                            className="accordion-button"
                            onClick={newGameAccordion}
                            style={{
                              backgroundColor: "#d7d7d7",
                              color: "black",
                              borderColor: "#00137f",
                              paddingLeft: "20px",
                              cursor: "pointer",
                              display: "inline-block",
                            }}
                          >
                            New Games
                            <span
                              style={{
                                position: "absolute",
                                right: "20px",
                                top: "50%",
                                transform: "translateY(-50%)",
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                className={
                                  isNewGamesCollapsed
                                    ? "bi bi-chevron-down"
                                    : "bi bi-chevron-up"
                                }
                                viewBox="0 0 16 16"
                                style={{
                                  background: "none",
                                }}
                              >
                                <path
                                  fillRule="evenodd"
                                  d={
                                    isNewGamesCollapsed
                                      ? "M7.646 11.354a.5.5 0 0 0 .708 0l3.5-3.5a.5.5 0 0 0-.708-.708L8 10.293l-3.646-3.647a.5.5 0 1 0-.708.708l3.5 3.5z"
                                      : "M7.646 4.646a.5.5 0 0 1 .708 0l3.5 3.5a.5.5 0 0 1-.708.708L8 5.707 4.354 9.354a.5.5 0 1 1-.708-.708l3.5-3.5z"
                                  }
                                />
                              </svg>
                            </span>
                          </div>
                          <div
                            className={`collapse ${
                              isNewGamesCollapsed ? "" : "show"
                            }`}
                            style={{
                              backgroundColor: "#d7d7d7",
                              padding: "20px",
                              cursor: "pointer",
                            }}
                          >
                            <div className="row img_row">
                              {gameTypes
                                ?.find((gt) => gt.id == selectedGameType)
                                ?.newgames?.map((single) => {
                                  return (
                                    <div
                                      className="col-lg-3 col-6 mt-2"
                                      onClick={() => {
                                        _openGameUrl(single.game_id);
                                      }}
                                    >
                                      <img
                                        style={{ borderRadius: "10px" }}
                                        src={`https://gamlabs.prerelease-env.biz/game_pic/rec/160/${single.game_id}.png`}
                                      />
                                    </div>
                                  );
                                }) ??
                                gameTypes
                                  .filter((gt) =>
                                    tableGames.includes(gt.description)
                                  )
                                  .map((single) => {
                                    return single.newgames.map((game) => {
                                      return (
                                        <>
                                          <div
                                            className="col-lg-3 col-6 mt-2"
                                            onClick={() => {
                                              _openGameUrl(game.game_id);
                                            }}
                                          >
                                            <img
                                              style={{ borderRadius: "10px" }}
                                              src={`https://gamlabs.prerelease-env.biz/game_pic/rec/160/${game.game_id}.png`}
                                            />
                                          </div>
                                        </>
                                      );
                                    });
                                  })}
                            </div>
                          </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="accordion mt-3">
                        <div
                          className="accordion-button"
                          onClick={popularGameAccordion}
                          style={{
                            backgroundColor: "#d7d7d7",

                            color:"black",

                            borderColor: "#00137f",

                            paddingLeft: "20px",

                            cursor: "pointer",

                            display: "inline-block",
                          }}
                        >
                          Popular Games
                          <span
                            style={{
                              position: "absolute",

                              right: "20px",

                              top: "50%",

                              transform: "translateY(-50%)",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="currentColor"
                              className={
                                isCollapsed
                                  ? "bi bi-chevron-down"
                                  : "bi bi-chevron-up"
                              }
                              viewBox="0 0 16 16"
                              style={{
                                background: "none",
                              }}
                            >
                              <path
                                fillRule="evenodd"
                                d={
                                  isCollapsed
                                    ? "M7.646 11.354a.5.5 0 0 0 .708 0l3.5-3.5a.5.5 0 0 0-.708-.708L8 10.293l-3.646-3.647a.5.5 0 1 0-.708.708l3.5 3.5z"
                                    : "M7.646 4.646a.5.5 0 0 1 .708 0l3.5 3.5a.5.5 0 0 1-.708.708L8 5.707 4.354 9.354a.5.5 0 1 1-.708-.708l3.5-3.5z"
                                }
                              />
                            </svg>
                          </span>
                        </div>

                        <div
                          className={`collapse ${isCollapsed ? "" : "show"}`}
                          style={{
                            backgroundColor: "#d7d7d7",

                            padding: "20px",

                            cursor: "pointer",
                          }}
                        >
                          <div className="row img_row">
                            {gameTypes

                              ?.find((gt) => gt.id == selectedGameType)

                              ?.populargames?.map((single) => {
                                return (
                                  <div
                                    className="col-lg-3 col-6 mt-2"
                                    onClick={() => {
                                      _openGameUrl(single.game_id);
                                    }}
                                  >
                                    <img
                                      style={{ borderRadius: "10px" }}
                                      src={`https://gamlabs.prerelease-env.biz/game_pic/rec/160/${single.game_id}.png`}
                                    />
                                  </div>
                                );
                              }) ??
                              gameTypes

                                .filter((gt) =>
                                  tableGames.includes(gt.description)
                                )

                                .map((single) => {
                                  return single.populargames.map((game) => {
                                    return (
                                      <>
                                        <div
                                          className="col-lg-3 col-6 mt-2"
                                          onClick={() => {
                                            _openGameUrl(game.game_id);
                                          }}
                                        >
                                          <img
                                            style={{ borderRadius: "10px" }}
                                            src={`https://gamlabs.prerelease-env.biz/game_pic/rec/160/${game.game_id}.png`}
                                          />
                                        </div>
                                      </>
                                    );
                                  });
                                })}
                          </div>
                        </div>
                    </div>

                    <div className="accordion mt-3">
                        <div
                          className="accordion-button"
                          style={{
                            backgroundColor: "#d7d7d7",
                            color:"black",
                            borderColor: "#00137f",
                            paddingLeft: "20px",
                            cursor: "pointer",
                            display: "inline-block",
                          }}
                        >
                          All Games
                        </div>
                        <div
                          className={`collapse ${
                            isCollapsed ? "show" : "show"
                          }`}
                          style={{
                            backgroundColor: "#d7d7d7",
                            padding: "20px",
                            cursor: "pointer",
                          }}
                        >
                          <div className="row img_row">
                            {gameTypes
                              ?.find((gt) => gt.id == selectedGameType)
                              ?.games?.map((single) => {
                                return (
                                  <div
                                    className="col-lg-3 col-6 mt-2"
                                    onClick={() => {
                                      _openGameUrl(single.game_id);
                                    }}
                                  >
                                    <img
                                      style={{ borderRadius: "10px" }}
                                      src={`https://gamlabs.prerelease-env.biz/game_pic/rec/160/${single.game_id}.png`}
                                    />
                                  </div>
                                );
                              }) ??
                              gameTypes
                                .filter((gt) =>
                                  tableGames.includes(gt.description)
                                )
                                .map((single) => {
                                  return single.games.map((game) => {
                                    return (
                                      <>
                                        <div
                                          className="col-lg-3 col-6 mt-2"
                                          onClick={() => {
                                            _openGameUrl(game.game_id);
                                          }}
                                        >
                                          <img
                                            style={{ borderRadius: "10px" }}
                                            src={`https://gamlabs.prerelease-env.biz/game_pic/rec/160/${game.game_id}.png`}
                                          />
                                        </div>
                                      </>
                                    );
                                  });
                                })}
                          </div>
                        </div>
                    </div>
                  </>
                )}
                <ReactModal
                  style={customStyles}
                  isOpen={!!iframeURL}
                  onRequestClose={() => setIframeURL(null)}
                  className="Casino_Games"
                >
                  <Iframe
                    url={iframeURL}
                    className="myClassname casino_game_iframe"
                    display="initial"
                    position="relative"
                  />
                </ReactModal>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Casinos;
